import "./App.css";
import { Route, Routes } from "react-router-dom";

import Home from "./components/home/Home";
import { useState } from "react";

const App = () => {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <div>
      <Routes>
        <Route
          path="/"
          index
          element={<Home navOpen={navOpen} setNavOpen={setNavOpen} />}
        />
      </Routes>
    </div>
  );
};

export default App;
