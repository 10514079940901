import stl from "./Chart.module.css";
import { TbLockCheck } from "react-icons/tb";
import { GiBurningSkull } from "react-icons/gi";
import { AiFillPrinter } from "react-icons/ai";
import { FaExchangeAlt } from "react-icons/fa";
import { RiNumbersFill } from "react-icons/ri";
import { FaTheaterMasks } from "react-icons/fa";
import { GiCapybara } from "react-icons/gi";
import { FaPeopleCarry } from "react-icons/fa";
import { FaSyringe } from "react-icons/fa";
import Marquee from "react-fast-marquee";

const Chart = () => {
  return (
    <div className={stl.chart}>
      <div className={stl.centerBox}>
        <h2 className={stl.safe}>
          Turbo <span className={stl.purple}> Safe</span>
        </h2>
        <div className={stl.marqueeWrap}>
          <Marquee speed={300}>
            <div className={stl.box}>
              <TbLockCheck className={stl.icon} />
              <span>Renounced</span>
            </div>
            <div className={stl.box}>
              <GiBurningSkull className={stl.icon} />
              <span>Liquidity Burned</span>
            </div>
            <div className={stl.box}>
              <AiFillPrinter className={stl.icon} />
              <span>
                Mint Authority
                <br /> Revoked
              </span>
            </div>
            <div className={stl.box}>
              <FaExchangeAlt className={stl.icon} />
              <span>
                100% Tokens
                <br /> Circulating
              </span>
            </div>
            <div className={stl.box}>
              <RiNumbersFill className={stl.icon} />
              <span>
                Increasing
                <br /> Holder Count
              </span>
            </div>
            <div className={stl.box}>
              <GiCapybara className={stl.icon} />
              <span>
                1st Capybara
                <br /> On Solana
              </span>
            </div>
            <div className={stl.box}>
              <FaTheaterMasks className={stl.icon} />
              <span>
                Transparency
                <br /> Since Day 1
              </span>
            </div>
            <div className={stl.box}>
              <FaPeopleCarry className={stl.icon} />
              <span>
                Active
                <br /> Community
              </span>
            </div>
            <div className={stl.box}>
              <FaSyringe className={stl.icon} />
              <span>
                Team On
                <br /> Steroids
              </span>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Chart;
