import stl from "./OGToken.module.css";
import { motion as m, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import NumberCounter from "./NumberCounter";
import TradingView from "./TradingView";
import { useState, useEffect } from "react";

const OGToken = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  const wrapRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: wrapRef,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.7, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.3, 1]);

  const [tcapPrice, setTCAPPrice] = useState(null);

  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
        const response = await fetch(
          "https://price.jup.ag/v4/price?ids=RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch SOL price");
        }
        const data = await response.json();
        const solData =
          data?.data["RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL"];
        if (solData) {
          // console.log(solData.price);
          setTCAPPrice(solData.price);
        } else {
          throw new Error("SOL price data not found");
        }
      } catch (error) {
        console.error(error);
        setTCAPPrice(null);
      }
    };

    fetchSolPrice();
  }, []);

  return (
    <m.div className={stl.ogtoken} ref={wrapRef}>
      <m.h1
        className={stl.hero}
        style={{ scale: scaleProgress, opacity: opacityProgress }}
      >
        Original Capybara
        <br /> <span className={stl.on}> On</span>
        <img
          src="./images/OnSolana.svg"
          alt="Solana svg"
          className={stl.solanaSvg}
        />
      </m.h1>
      <div className={stl.priceBox}>
        <span className={stl.tcapPrice}>$TCAP Price</span>
        <m.h2
          className={stl.price}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          ref={ref}
        >
          ${inView && <NumberCounter n={tcapPrice} />}
        </m.h2>
      </div>
      <div className={stl.chart}>
        <TradingView />
      </div>
    </m.div>
  );
};

export default OGToken;
