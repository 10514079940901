import stl from "./Tokenomics.module.css";
import { motion as m, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

const Tokenomics = () => {
  const wrapRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: wrapRef,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
  return (
    <div className={stl.tokenomics} ref={wrapRef}>
      <div className={stl.dropBox}>
        <h1 className={stl.hero}>Capymetrics</h1>
      </div>
      <div className={stl.metricsTiles}>
        <div className={stl.sphere}></div>
        <div className={stl.tilesWrapper}>
          <m.div
            className={stl.tile}
            style={{ scale: scaleProgress, opacity: opacityProgress }}
          >
            <span className={stl.textSpan}>
              100%
              <br /> Fair Launched
            </span>
          </m.div>
          <m.div
            className={stl.tile}
            style={{
              scale: scaleProgress,
              opacity: opacityProgress,
            }}
          >
            <span className={stl.textSpan}>
              0%
              <br /> Sniped
            </span>
          </m.div>
          <m.div
            className={stl.tile}
            style={{
              scale: scaleProgress,
              opacity: opacityProgress,
            }}
          >
            <span className={stl.textSpan}>
              5% <br /> Team
            </span>
          </m.div>
          <m.div
            className={stl.tile}
            style={{ scale: scaleProgress, opacity: opacityProgress }}
          >
            <span className={stl.textSpan}>
              2.5% <br /> Burn
            </span>
          </m.div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
