/* eslint-disable jsx-a11y/anchor-is-valid */
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { createJupiterApiClient } from "@jup-ag/api";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  PublicKey,
  VersionedTransaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { Buffer } from "buffer";
import Decimal from "decimal.js";
import { useCallback, useEffect, useState } from "react";
import Tokens from "./tokens/tokens.json";
import Loader from "./Loader";
import "./Swap.css";
import stl from "./Swap.module.css";
import { FaArrowRotateRight } from "react-icons/fa6";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { SlWallet } from "react-icons/sl";
import { FaRotate } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

const jupiterApi = createJupiterApiClient();
const allTokens = Tokens;
const DEFAULT_AMOUNT = 0.001;
const JUPITER_REFERRAL_PROGRAM_ADDRESS =
  "REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3"; // this is static
const JUPITER_REFERRAL_ACCOUNT_ADDRESS =
  process.env.REACT_APP_JUPITER_REFERRAL_ADDRESS || "";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Swap = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [quote, setQuote] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const [amount, setAmount] = useState(DEFAULT_AMOUNT);
  const [transaction, setTransaction] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("pending");
  const [swappingTokens, setSwappingTokens] = useState(false);
  const [userHeldTokens, setUserHeldTokens] = useState([]);
  const [activeHeldBalance, setActiveHeldBalance] = useState(null);
  const [activePairName, setActivePairName] = useState(allTokens[0].symbol);
  const [quoteRoutes, setQuoteRoutes] = useState([]);
  const [formValue, setFormValue] = useState({
    amount: DEFAULT_AMOUNT, // unit in lamports (Decimals)
    sourceToken: allTokens[0],
    targetToken: allTokens[1],
  });

  const walletToQuery = wallet.publicKey?.toBase58();

  const getTokenAccounts = useCallback(
    async (wallet, solanaConnection) => {
      if (!walletToQuery) return;
      if (userHeldTokens.length > 0) return;
      const filters = [
        {
          dataSize: 165,
        },
        {
          memcmp: {
            offset: 32,
            bytes: wallet,
          },
        },
      ];
      const accounts = await solanaConnection.getParsedProgramAccounts(
        TOKEN_PROGRAM_ID,
        { filters: filters }
      );

      const allTokens = accounts.map((account, i) => {
        const parsedAccountInfo = account.account.data;
        const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
        const tokenBalance =
          parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];

        return { token: mintAddress, balance: tokenBalance };
      });

      const walletKey = new PublicKey(walletToQuery);
      const balance = await connection.getBalance(walletKey);
      const solBalance = balance / LAMPORTS_PER_SOL;
      setUserHeldTokens([
        ...allTokens,
        {
          token: "So11111111111111111111111111111111111111112",
          balance: solBalance,
        },
      ]);
    },
    [userHeldTokens.length, walletToQuery, connection]
  );

  useEffect(() => {
    if (userHeldTokens.length > 0) return;
    getTokenAccounts(walletToQuery, connection);
  }, [userHeldTokens, connection, getTokenAccounts, walletToQuery]);

  const getQuote = useCallback(async () => {
    setLoading(true);
    setSwappingTokens(true);

    try {
      const b = await jupiterApi.quoteGet({
        inputMint: formValue.sourceToken.address,
        outputMint: formValue.targetToken.address,
        amount: new Decimal(formValue.amount)
          .mul(10 ** (formValue.sourceToken.decimals || 1))
          .toNumber(),
        platformFeeBps: 20, // 0.2%
        autoSlippage: true,
        computeAutoSlippage: true,
      });
      console.log("quote", b);
      await delay(500); // arbitrary ro make sure loading indicator is up for long enough
      setQuote(b);
      setQuoteRoutes(b.routePlan);

      setTimeout(() => {
        setSwappingTokens(false);
        setLoading(false);
      }, 1000);
    } catch (e) {
      console.error(e.message);
      setSwappingTokens(false);
      setLoading(false);
      // setError(e.message);
    }
  }, [formValue]);

  useEffect(() => {
    if (userHeldTokens.length === 0) return;
    let activeToken = 0;
    userHeldTokens.forEach((token) => {
      if (token.token === formValue.sourceToken.address) {
        activeToken = token.balance;
      }
      return null;
    });
    setActiveHeldBalance(activeToken);
  }, [formValue, userHeldTokens]);

  useEffect(() => {
    setError("");

    if (
      formValue.amount &&
      !new Decimal(formValue.amount).isZero() &&
      new Decimal(formValue.amount).isPositive()
    ) {
      console.log("form", formValue);
      getQuote();
    }
  }, [formValue, getQuote]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setFormValue((val) => ({
        ...val,
        amount,
      }));
    }, 600);
    return () => clearTimeout(delayInputTimeoutId);
  }, [amount]);

  useEffect(() => {
    let intervalId;

    const getStatusInterval = () => intervalId;

    const a = async (tx) => {
      const status = await connection.getSignatureStatus(tx, {
        searchTransactionHistory: true,
      });
      const interval = getStatusInterval();
      console.log("sig status", tx, status, interval);
      if (
        status.value &&
        (status.value.confirmationStatus === "finalized" ||
          status.value.confirmationStatus === "confirmed")
      ) {
        setNotification("complete");
        if (interval) {
          clearInterval(interval);
        }
        await delay(3000);
        setShowNotification(false);
        setTransaction("");
        return true;
      } else {
        setNotification("pending");
        return false;
      }
    };

    const b = async (tx) => {
      intervalId = setInterval(() => a(tx), 7500);
    };

    if (transaction && connection) {
      setShowNotification(true);
      a(transaction).then((r) => {
        if (!r && !getStatusInterval()) b(transaction);
      });
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [transaction, connection]);

  const doSwap = async (e) => {
    e.preventDefault();
    setError("");
    setIsSwapping(true);
    if (!wallet && !wallet.publicKey) return;
    const referralAccountPubkey = new PublicKey(
      JUPITER_REFERRAL_ACCOUNT_ADDRESS
    ); // key for referral program account
    const [feeAccount] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("referral_ata"),
        referralAccountPubkey.toBuffer(),
        new PublicKey(quote.outputMint).toBuffer(),
      ],
      new PublicKey(JUPITER_REFERRAL_PROGRAM_ADDRESS) // the Referral Program
    );

    const swap = await jupiterApi.swapPost({
      swapRequest: {
        userPublicKey: wallet.publicKey.toString(),
        quoteResponse: quote,
        feeAccount: feeAccount.toString(),
      },
    });
    console.log("swap", swap);
    const swapTransactionBuf = Buffer.from(swap.swapTransaction, "base64");
    var transaction = VersionedTransaction.deserialize(swapTransactionBuf);
    console.log("transaction", transaction);
    if (wallet && wallet.sendTransaction) {
      try {
        const tx = await wallet.sendTransaction(transaction, connection);
        setTransaction(tx);
        setIsSwapping(false);
        console.log(tx);
      } catch (e) {
        getQuote();
        setIsSwapping(false);
        console.log(e);
        // setError(e.message);
      }
    }
  };

  const doFlip = async (e) => {
    e.preventDefault();
    setError("");
    const { sourceToken, targetToken } = formValue;
    setFormValue((val) => ({
      ...val,
      targetToken: sourceToken,
      sourceToken: targetToken,
    }));
    setAmount(
      (quote &&
        new Decimal(quote.outAmount.toString())
          .div(
            10 **
              (allTokens.find((a) => a.address === quote.outputMint).decimals ||
                1)
          )
          .toNumber()) ||
        DEFAULT_AMOUNT
    );
    setActivePairName(formValue.targetToken.symbol);
  };

  const updateSourceToken = (e) => {
    if (loading) return;
    const sourceToken = allTokens[e.currentTarget.value];

    if (
      formValue.targetToken &&
      formValue.targetToken.address === sourceToken.address
    ) {
      doFlip(e);
    } else {
      setActivePairName(sourceToken.symbol);

      setFormValue((val) => ({
        ...val,
        sourceToken,
      }));
    }
  };

  const setHalf = () => {
    if (activeHeldBalance === 0) return;
    setAmount((activeHeldBalance / 2).toFixed(8));
    setFormValue((prevState) => {
      return {
        ...prevState,
        amount: (activeHeldBalance / 2).toFixed(4),
      };
    });
  };

  const setMax = () => {
    let multiplier = 1;
    console.log(activePairName);
    if (activePairName === "SOL") {
      multiplier = 0.999;
    }

    if (activeHeldBalance === 0) return;
    setAmount(+(activeHeldBalance * multiplier).toFixed(8));
    setFormValue((prevState) => {
      return {
        ...prevState,
        amount: +(activeHeldBalance * multiplier).toFixed(4),
      };
    });
  };

  useEffect(() => {
    if (showNotification && notification !== "Pending") {
      getQuote();
      getTokenAccounts();
    }
  }, [showNotification, notification, getQuote, getTokenAccounts]);

  return (
    <>
      {showNotification && (
        <div className={stl.swapNotification}>
          {notification === "pending" && <div className={stl.spinner}></div>}
          {notification !== "pending" && (
            <div className={stl.check}>
              <FaCheck className={stl.checkIcon} />
            </div>
          )}

          <div className="swap-notification-text">
            Transaction {notification === "pending" ? `Processing` : `Complete`}
          </div>
          <div>
            <a
              href={`https://solscan.io/tx/${transaction}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./images/external-link.svg"
                className="swap-notification-image"
                alt="link to transaction"
              />
            </a>
          </div>
        </div>
      )}

      <div className={stl.swapContainer}>
        <img
          src="./images/Flipped.webp"
          alt="Capybara"
          className={stl.topCapy}
        />
        <div className={stl.innerSwapContainer}>
          {loading && (
            <div className={stl.swapLoader}>
              <Loader />
            </div>
          )}
          <div className={stl.topbar}>
            <button className={stl.swapRefreshButton} onClick={getQuote}>
              <FaArrowRotateRight className={stl.rotateBtn} />
            </button>
            <span className={stl.turboSwap}>Turbo Swap</span>
            <WalletMultiButton className={stl.connectCta} />
          </div>
          <div className={stl.amountBar}>
            {userHeldTokens.length > 0 && (
              <>
                <SlWallet />
                <span>
                  {(userHeldTokens && activeHeldBalance && activeHeldBalance) ||
                    0}
                </span>
                <span>{activePairName}</span>
                <button className={stl.portionCta} onClick={setHalf}>
                  Half
                </button>
                <button className={stl.portionCta} onClick={setMax}>
                  Max
                </button>
              </>
            )}
          </div>
          <div className={stl.swapSelectContainer}>
            <label htmlFor="sourceToken" className={stl.swapLabel}>
              Swap
            </label>
            <div className={stl.sphere}></div>
            <div className={stl.swapSelectContainerImage}>
              <img
                className={stl.swapSelectImage}
                src={formValue.sourceToken.logoURI}
                alt="token-logo"
              />
            </div>
            <select
              id="sourceToken"
              name="sourceToken"
              className={stl.swapSelect}
              value={allTokens.findIndex(
                (a) => a.address === formValue.sourceToken.address
              )}
              onChange={updateSourceToken}
            >
              {allTokens.map((token, index) => {
                return (
                  <option
                    style={{ backgroundImage: `url('${token.logoURI}')` }}
                    key={`in_${token.address}`}
                    value={index}
                  >
                    {token.symbol}
                  </option>
                );
              })}
            </select>

            <input
              name="amount"
              autoComplete="off"
              id="amount"
              className={stl.swapAmount}
              value={amount.toString()}
              type="text"
              onInput={(e) => {
                const inputValue = e.target?.value;
                if (/^\d*\.?\d*$/.test(inputValue)) {
                  setAmount(inputValue || "");
                }
              }}
            />
          </div>
          <div className={stl.swapMiddleButtons}>
            <div className={stl.flipWrapper}>
              <button
                className={stl.swapFlipButton}
                onClick={doFlip}
                disabled={swappingTokens ? true : false}
              >
                <HiMiniArrowsUpDown className={stl.updownarrows} />
              </button>
            </div>
          </div>
          <div className={stl.swapSelectContainer}>
            <label htmlFor="targetToken" className={stl.swapLabel}>
              For
            </label>
            <div className={stl.swapSelectContainerImage}>
              <img
                className={stl.swapSelectImage}
                src={formValue.targetToken.logoURI}
                alt="token-logo"
              />
            </div>
            <select
              id="targetToken"
              name="targetToken"
              className={stl.swapSelect}
              value={allTokens.findIndex(
                (a) => a.address === formValue.targetToken.address
              )}
              onChange={(e) => {
                if (loading) return;
                const targetToken = allTokens[e.currentTarget.value];
                if (
                  formValue.sourceToken &&
                  formValue.sourceToken.address === targetToken.address
                ) {
                  doFlip(e);
                } else {
                  setFormValue((val) => ({
                    ...val,
                    targetToken,
                  }));
                }
              }}
            >
              {allTokens.map((token, index) => {
                return (
                  <option key={`out_${token.address}`} value={index}>
                    {token.symbol}
                  </option>
                );
              })}
            </select>

            <input
              name="target-amount"
              id="target-amount"
              autoComplete="off"
              className={stl.swapAmount}
              value={
                (quote &&
                  quote.outAmount &&
                  new Decimal(quote.outAmount.toString())
                    .div(
                      10 **
                        (allTokens.find((a) => a.address === quote.outputMint)
                          .decimals || 1)
                    )
                    .toString()) ||
                ""
              }
              type="text"
              disabled={true}
            />
            <div className={stl.sphere2}></div>
          </div>
          <div className={stl.swapFooter}>
            <div className={stl.hopsBar}>
              {Object.entries(quote).length > 0 && (
                <>
                  <div className={stl.hopCount}>
                    {quote.routePlan.length} Hop
                    {quote.routePlan.length === 1 ? "" : "s"}
                  </div>
                  <span className={stl.viaSpan}>
                    Via{" "}
                    {quoteRoutes &&
                      quoteRoutes.map((quote, index) => (
                        <span key={index} className={stl.quoteLabel}>
                          {quote.swapInfo.label}
                          {index < quoteRoutes.length - 1 && ", "}
                        </span>
                      ))}
                  </span>
                </>
              )}
            </div>
            <button
              onClick={doSwap}
              className={`${stl.swapButton} ${
                !wallet || !wallet.publicKey ? "swapButtonDisabled" : ""
              } `}
              disabled={isSwapping ? true : false}
              style={{ pointerEvents: walletToQuery ? "initial" : "none" }}
            >
              {!isSwapping && (
                <>{walletToQuery ? "Swap" : "No Wallet Connected"}</>
              )}
              {isSwapping && <FaRotate className={stl.rotater} />}
            </button>
          </div>
          {error && <div className={stl.swapError}>{error}</div>}
        </div>
      </div>
    </>
  );
};

export default Swap;
