import stl from "./Footer.module.css";
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
  return (
    <div className={stl.footer}>
      <div className={stl.centerBox}>
        <div className={stl.imgWrap}>
          <img
            src="./images/Flipped.webp"
            alt="Capybara"
            className={stl.capybara}
          />
        </div>
        <h2 className={stl.hero}>
          Turbo Capybara
          <br />© Barn Labs
        </h2>
        <BsTwitterX
          className={stl.xIcon}
          onClick={() => window.open("https://twitter.com/tCAPonSOL", "_blank")}
        />
      </div>
    </div>
  );
};

export default Footer;
