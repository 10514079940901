import MainCanvas from "./canvas/MainCanvas";
import Swap from "./Swap";
import stl from "./SwapHeader.module.css";
import CAPYBARAIMGS from "./canvas/CapybaraImgs";

const SwapHeader = () => {
  return (
    <div className={stl.swapheader}>
      <div className={stl.capyBlock}>
        <img
          src="./images/Bigcapy.webp"
          alt="Bigcapybara"
          className={stl.bigCapy}
        />
        <div className={stl.textBlock}>
          <h1 className={stl.turbo}>Turbo</h1>
          <h1 className={stl.capybara}>Capybara</h1>
        </div>
      </div>
      <Swap />
      <div className={stl.spheres}></div>
      <MainCanvas
        sourceImgs={CAPYBARAIMGS}
        minSpeed={0.3}
        maxSpeed={0.7}
        renderCount={6}
      />
    </div>
  );
};

export default SwapHeader;
