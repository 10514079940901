import stl from "./Believe.module.css";
import { useState, useEffect, useRef } from "react";
import { FiCopy } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

const Believe = () => {
  const inputRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  const [fadingState, setFadingState] = useState(false);

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 1500);
    }
  }, [clicked]);

  useEffect(() => {
    setTimeout(() => {
      setFadingState(null);
    }, 1500);
  }, []);

  const handleClick = () => {
    setClicked(true);

    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); /* For mobile devices */

      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  };

  const handleBuyTcap = () => {
    setFadingState(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    setTimeout(() => {
      setFadingState(false);
    }, 2000);
    setTimeout(() => {
      setFadingState(null);
    }, 3000);
  };

  return (
    <div className={stl.believe}>
      <div
        className={`${stl.transitionOverlay} ${
          fadingState ? stl.fadeIn : stl.fadeOut
        } ${fadingState === null ? stl.hide : ""}`}
      ></div>
      <h2 className={stl.hero}>Active Markets</h2>
      <div className={stl.marketsbox}>
        <div
          className={stl.marketTile}
          onClick={() => window.open("https://raydium.io/swap/", "_blank")}
        >
          <img
            src="./images/Raydium.svg"
            alt="Raydium"
            className={stl.rayIcon}
          />
          <span className={stl.marketSpan}>Raydium</span>
        </div>
        <div
          className={stl.marketTile}
          onClick={() => window.open("https://jup.ag/", "_blank")}
        >
          <img
            src="./images/Jupiter.svg"
            alt="Jupiter"
            className={stl.jupIcon}
          />
          <span className={stl.marketSpan}>Jupiter</span>
        </div>
        <div
          className={stl.marketTile}
          onClick={() => window.open("https://pump.fun", "_blank")}
        >
          <img
            src="./images/PumpLogo.jpg"
            alt="Dextools"
            className={stl.jupIcon}
          />
          <span className={stl.marketSpan}>Pump.fun</span>
        </div>
      </div>
      <div className={stl.caBox}>
        <div className={stl.walletCopyBox}>
          <input
            type="text"
            ref={inputRef}
            className={stl.addressinput}
            defaultValue={"Coming Soon!"}
          />
          <button
            className={`${stl.copyCta} ${clicked ? stl.clickedCta : ""}`}
            onClick={handleClick}
          >
            {clicked ? <FaCheck /> : <FiCopy />}
          </button>
        </div>
      </div>
      <div className={stl.stopFading}>
        <img
          src="./images/Rich.jpeg"
          alt="Big capybara"
          className={stl.bigCapy}
        />
        <h2 className={stl.rich}>Choose Rich</h2>
        <button className={stl.richCta} onClick={handleBuyTcap}>
          Buy $TCAP
        </button>
      </div>
      <div className={stl.sphere}></div>
    </div>
  );
};

export default Believe;
