import { memo } from "react";
import stl from "./Loader.module.css";

const sizeMap = {
  large: "200px",
  medium: "75px",
  small: "50px",
};

const LoaderIcon = (props) => (
  <div
    className={stl.loaderContainer}
    style={{ width: sizeMap[props.size], height: sizeMap[props.size] }}
  >
    <img
      src="./images/Running.gif"
      alt="Running Capybara"
      className={stl.running}
    />
  </div>
);

LoaderIcon.displayName = "LoaderIcon";

export default memo(LoaderIcon);
