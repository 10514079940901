import { Helmet } from "react-helmet";
import stl from "./Home.module.css";
import MarketCap from "./marketcap/MarketCap";
import Tokenomics from "./tokenomics/Tokenomics";
import Chart from "./chart/Chart";
import Contestants from "./contestants/Contestants";
import Roadmap from "./roadmap/Roadmap";
import Believe from "./believe/Believe";
import Footer from "./footer/Footer";
import SwapHeader from "./swapheader/SwapHeader";
import OGToken from "./ogtoken/OGToken";

const Home = ({ navOpen, setNavOpen }) => {
  return (
    <div className={`${stl.home} ${navOpen ? stl.overflowHidden : ""}`}>
      <Helmet>
        <title>Turbo Capybara</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="Turbo Capybara" />
        <meta name="twitter:creator" content="@tCAPonSOL" />
        <meta property="og:url" content="https://turbocapybara.com" />
        <meta property="og:title" content="Turbo Capybara on Solana" />
        <meta
          property="og:description"
          content="Turbo Capybara is Solana's friendliest Memecoin. Through love and kindness will he conquer."
        />
        <meta
          property="og:image"
          content="https://turbocapybara.com/images/Card.webp"
        />
      </Helmet>
      <SwapHeader />

      <OGToken />
      <MarketCap />
      <Tokenomics />
      <Chart />
      <Contestants />
      <Roadmap />
      <Believe />
      <Footer />
    </div>
  );
};

export default Home;
