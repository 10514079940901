import stl from "./Contestants.module.css";

const Contestants = () => {
  return (
    <div className={stl.contestants}>
      <h1 className={stl.hero}>Ready to conquer the leaderboards</h1>
      <div className={stl.midBLock}>
        <div className={stl.capyBlock}>
          <div className={stl.imgWrapper}>
            <img src="./images/Phat.webp" alt="Partyhat" className={stl.phat} />
            <img
              src="./images/Bathing.webp"
              alt="Bathing"
              className={stl.bathing}
            />
            <h2 className={stl.turbotitle}>Turbo Capybara</h2>
          </div>
        </div>
        <h2 className={stl.vs}>VS</h2>
        <div className={stl.innerRight}>
          <div className={stl.rightGrid}>
            <div className={stl.block}>
              <img
                src="./images/CMC.png"
                alt="Coinmarketcap"
                className={stl.cmc}
              />
              CoinMarketCap
            </div>
            <div className={stl.block}>
              <img
                src="./images/Coingecko.webp"
                alt="Coingecko"
                className={stl.gecko}
              />
              Coingecko
            </div>
            <div className={`${stl.block} ${stl.ray}`}>
              <img
                src="./images/Raydium.png"
                alt="Raydium"
                className={stl.gecko}
              />
              Raydium
            </div>
            <div className={`${stl.block} ${stl.ray}`}>
              <img
                src="./images/Jupiter.png"
                alt="Jupiter"
                className={stl.gecko}
              />
              Jupiter
            </div>
            <div className={`${stl.block} ${stl.ray}`}>
              <img
                src="./images/Dextools.png"
                alt="Dextools"
                className={stl.gecko}
              />
              Dextools
            </div>
            <div className={`${stl.block} ${stl.ray}`}>
              <img
                src="./images/Birdeye.png"
                alt="Birdeye"
                className={stl.gecko}
              />
              Birdeye
            </div>
          </div>
          <div className={stl.bar}></div>
          <div className={stl.memeList}>
            <div className={stl.memeTile}>
              <img src="./images/Duko.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/Epep.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/Wif.webp" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/Pepe.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/Samo.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Bonk.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>

            <div className={stl.memeTile}>
              <img
                src="./images/Bome.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Myro.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Popcat.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Ponke.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Peng.webp"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Silly.png"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/Solama.png"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/Guac.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/MEW.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/SMOG.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img src="./images/nub.png" alt="Coin" className={stl.coinImg} />
            </div>
            <div className={stl.memeTile}>
              <img
                src="./images/catwifbag.png"
                alt="Coin"
                className={stl.coinImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contestants;
