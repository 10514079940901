import stl from "./Roadmap.module.css";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoRocketSharp } from "react-icons/io5";
import { LuClipboardList } from "react-icons/lu";
import { GiSpeaker } from "react-icons/gi";
import { FaParachuteBox } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { MdCasino } from "react-icons/md";
import { GiCapybara } from "react-icons/gi";
import { BsFillPinMapFill } from "react-icons/bs";
import { useState } from "react";
import { GoArrowSwitch } from "react-icons/go";

const Roadmap = () => {
  const [shownTabs, setShownTabs] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  });

  const toggleTextModal = (index) => {
    setShownTabs((prevState) => {
      const updatedTabs = {};

      for (const key in prevState) {
        updatedTabs[key] = false;
      }

      updatedTabs[index] = !prevState[index];

      return updatedTabs;
    });
  };

  return (
    <div className={stl.roadmap}>
      <div className={stl.dropBox}>
        <div className={stl.blackBox}></div>
        <h1 className={stl.hero}>Q2 Roadmap</h1>
      </div>
      <div className={stl.mainWrapper}>
        <div className={stl.grassBlock}></div>
        <div className={stl.roadmapBlock}>
          <div className={stl.leftCard} onClick={() => toggleTextModal(1)}>
            {shownTabs[1] === false && (
              <>
                <FaFileInvoiceDollar className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Presale</span>
                </div>
              </>
            )}
            {shownTabs[1] === true && (
              <>
                <FaFileInvoiceDollar className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    $TCAP Has no presale. Launch was conducted on pump.fun for a
                    true fair launch. Granting everyone an equal opportunity to
                    buy $TCAP before we trade on Raydium.
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderLeft}`}></div>
          <div className={stl.rightCard} onClick={() => toggleTextModal(2)}>
            {shownTabs[2] === false && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Launch</span>
                </div>
                <IoRocketSharp className={stl.icon} />
              </>
            )}
            {shownTabs[2] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    Run it up Turbo! We're deploying to the Solana blockchain.
                    The beginning of an adventure to conquer the leaderboards,
                    and become the friendliest meme on chain!
                  </span>
                </div>
                <IoRocketSharp className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderRight}`}></div>
          <div className={stl.leftCard} onClick={() => toggleTextModal(3)}>
            {shownTabs[3] === false && (
              <>
                <FaParachuteBox className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Airdrop</span>
                </div>
              </>
            )}
            {shownTabs[3] === true && (
              <>
                <FaParachuteBox className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    We'll be airdropping goodies to our community members! Those
                    that stand out and prove themselves worthy will be contacted
                    for a cute Turbo Capybara Carepackage.
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderLeft}`}></div>
          <div className={stl.rightCard} onClick={() => toggleTextModal(4)}>
            {shownTabs[4] === false && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Listings</span>
                </div>
                <LuClipboardList className={stl.icon} />
              </>
            )}
            {shownTabs[4] === true && (
              <>
                <LuClipboardList className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    Capybara will knock on some doors to get our lovely asset
                    indexed across known aggregators.
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderRight}`}></div>
          <div className={stl.leftCard} onClick={() => toggleTextModal(5)}>
            {shownTabs[5] === false && (
              <>
                <GiSpeaker className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Marketing</span>
                </div>
              </>
            )}
            {shownTabs[5] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    Growing the brand through partnerships & befriending
                    communties.
                  </span>
                </div>
                <GiSpeaker className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderLeft}`}></div>
          <div className={stl.rightCard} onClick={() => toggleTextModal(6)}>
            {shownTabs[6] === false && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Turbo Swap</span>
                </div>
                <GoArrowSwitch className={stl.icon} />
              </>
            )}
            {shownTabs[6] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    We'll be iterating on our swap to make it your go-to DEX on
                    Solana!
                  </span>
                </div>
                <GoArrowSwitch className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderRight}`}></div>
          <div className={stl.leftCard} onClick={() => toggleTextModal(7)}>
            {shownTabs[7] === false && (
              <>
                <FaDiscord className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Discord Launch</span>
                </div>
              </>
            )}
            {shownTabs[7] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    Cave v0.1 where we never get to see any more daylight. A
                    vibrant hub where stoves are always lit, sentiment might be
                    good or shit. But Capybara is always in it.
                  </span>
                </div>
                <FaDiscord className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderLeft}`}></div>
          <div className={stl.rightCard} onClick={() => toggleTextModal(8)}>
            {shownTabs[8] === false && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Global Takeover</span>
                </div>
                <GiCapybara className={stl.icon} />
              </>
            )}
            {shownTabs[8] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    If you're reading this, you are so early. Capybara is aiming
                    to reach the masses. How? By being Capybara
                  </span>
                </div>
                <GiCapybara className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderRight}`}></div>
          <div className={stl.leftCard} onClick={() => toggleTextModal(9)}>
            {shownTabs[9] === false && (
              <>
                <MdCasino className={stl.icon} />
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>$TCAP Gaming</span>
                </div>
              </>
            )}
            {shownTabs[9] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    Feeling lucky? Towards the end of Q3 we hope to deliver some
                    $TCAP decentralized games. No fancy 3D, but engaging
                    nevertheless!
                  </span>
                </div>
                <MdCasino className={stl.icon} />
              </>
            )}
          </div>
          <div className={`${stl.border} ${stl.borderLeft}`}></div>
          <div className={stl.rightCard} onClick={() => toggleTextModal(10)}>
            {shownTabs[10] === false && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardTxt}>Q3 Roadmap</span>
                </div>
                <BsFillPinMapFill className={stl.icon} />
              </>
            )}
            {shownTabs[10] === true && (
              <>
                <div className={stl.textWrap}>
                  <span className={stl.cardDesc}>
                    In Q3 we'll look at the state of the project & market and
                    decide on our next moves. Behold!
                  </span>
                </div>
                <BsFillPinMapFill className={stl.icon} />
              </>
            )}
          </div>
        </div>
        <div className={stl.grassBlock}></div>
      </div>
    </div>
  );
};

export default Roadmap;
