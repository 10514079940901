import stl from "./MarketCap.module.css";
import { motion as m } from "framer-motion";
import { useState, useEffect } from "react";

const MarketCap = () => {
  const [looted, setLooted] = useState(false);
  const [totalCap, setTotalCap] = useState(1000000000);

  const incrementLoot = () => {
    setLooted(true);
    setTotalCap((prevState) => prevState + 1);
    setTimeout(() => {
      setLooted(false);
    }, 500);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      // setLooted(false);
      incrementLoot();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={stl.capOuterWrap}>
      <div className={stl.marketcap}>
        <span className={stl.runitup}>
          Run it up <span className={stl.red}>Turbo</span>
        </span>
        <div className={stl.cloudbar}>
          <div className={stl.blackBox}></div>
          <div className={stl.cloudWrapper}>
            <span className={stl.capSpan}>
              {totalCap.toLocaleString()} Supply
            </span>
            <img src="./images/Cloud.webp" alt="Cloud" className={stl.cloud} />
          </div>
        </div>
        <img
          src="./images/Castle.webp"
          alt="Mario Castle"
          className={stl.castle}
        />
        <div className={stl.barsWrap}>
          <button
            className={stl.coinBtn}
            onClick={incrementLoot}
            disabled={looted ? true : false}
          ></button>
          <img
            src="./images/Coin.png"
            alt="Mario coi"
            className={`${stl.coin} ${looted ? stl.upwards : ""}`}
          />
          <img src="./images/Bars.webp" alt="Bars" className={stl.bars} />
        </div>
        <img src="./images/Half.webp" alt="Mario Castle" className={stl.half} />
        <img src="./images/Flag.webp" alt="Flag" className={stl.flag} />
        <img
          src="./images/Pipe.webp"
          alt="Mario pipe"
          className={stl.sidepipe}
        />
        <div className={stl.runBar}>
          <m.img
            src="./images/Flipped.webp"
            alt="Capybara"
            className={stl.sideCapy}
          />
        </div>
        <div className={stl.pipewall}></div>
        <div className={stl.blackBox2}></div>
      </div>
    </div>
  );
};

export default MarketCap;
